var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slots-list"},[_c('a-row',{attrs:{"type":"flex","gutter":[ 8, 8 ]}},_vm._l((Object.entries(_vm.weekDaysFrontend)),function(ref,index){
var dayIndex = ref[0];
var daySlots = ref[1];
return _c('a-col',{key:index,attrs:{"flex":"1"}},[_c('a-card',{staticClass:"slot-card w-full max-w-full h-full flex flex-col",staticStyle:{"min-width":"150px"},attrs:{"body-style":{
          flex: 1
        },"size":"small","title":_vm.weekDayTitles[dayIndex]}},[(daySlots.slots.length)?_c('ul',_vm._l((daySlots.slots),function(slot,index){return _c('li',{key:index,staticClass:"mb-2 last:mb-0"},[_c('div',{staticClass:"flex justify-between"},[_c('div',[_c('a',{staticClass:"text-current hover:text-primary",on:{"click":function($event){$event.preventDefault();return _vm.editSlot(slot)}}},[_vm._v(" "+_vm._s(_vm.formatSlotTime(slot.value.time))+" ")]),_c('a-tooltip',{attrs:{"title":_vm.$t('ad_posts_only'),"trigger":_vm.tooltipTrigger()}},[(slot.value.ads_only)?_c('a-icon',{staticClass:"text-success cursor-pointer",attrs:{"type":"dollar"}}):_vm._e()],1)],1),_c('a',{attrs:{"title":_vm.$t('delete')},on:{"click":function($event){$event.preventDefault();return _vm.removeSlot(slot.guid)}}},[_c('a-icon',{staticClass:"text-danger hover:opacity-75",attrs:{"type":"delete"}})],1)])])}),0):_c('empty-data'),_c('template',{slot:"actions"},[_c('a-icon',{attrs:{"type":"plus"},on:{"click":function($event){return _vm.openAddSlotModal(dayIndex)}}})],1)],2)],1)}),1),(_vm.slotModel)?_c('a-modal',{attrs:{"centered":"","title":_vm.modalTitle,"ok-text":_vm.$t('save'),"ok-button-props":{
      props: {
        disabled: !_vm.slotModel.value.time
      }
    },"cancel-text":_vm.$t('cancel'),"after-close":_vm.handleModalCancel},on:{"ok":_vm.handleOkButtonClick},model:{value:(_vm.isSlotModalOpen),callback:function ($$v) {_vm.isSlotModalOpen=$$v},expression:"isSlotModalOpen"}},[_c('time-picker-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.slotModel.value,
          'key': 'time',
          'clearable': false,
          'time24hr': true,
        }
      }}}),(!_vm.adsOnly)?_c('switch-input',{staticClass:"mb-0",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.slotModel.value,
          'key': 'ads_only',
        }
      }}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }