








































import SlotsList from '@/components/Slots/SlotsList.vue'
import { successNotification } from '@/includes/services/NotificationService'
import { Slot } from '@/includes/types/Slots.types'

import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'

import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    SlotsList,
    ConfigField,
  }
})
export default class Slots extends Vue {

  saveSlots(slots: Array<Slot>) {
    this.$store.commit('set_slots_to_channel', slots)

    this.saveChannelConfig()
  }

  resetSlots():void {
    this.$confirm({
      title: this.$t('reset_slots_confirm_title').toString(),
      okText: this.$t('accept').toString(),
      cancelText: this.$t('reject').toString(),
      onOk: () => {
        this.$store.commit('reset_slots')
        this.$store.dispatch('save_active_channel_config', this.$route)
      },
    })
  }

  copySlots():void {
    this.$store.dispatch('setSlotsToCopy', this.$store.state.channelsState.activeChannel?.config.slots)
      .then(() => successNotification(this.$t('slots_copied').toString()))
  }

  pasteSlots():void {
    this.$confirm({
      title: this.$t('paste_slots_confirm_title').toString(),
      onOk: () => {
        this.$store.dispatch('setSlotsToChannel', this.$store.state.channelsState.copiedSlots)
          .then(res => {
            this.saveChannelConfig()
          })
      },
      okText: this.$t('accept').toString(),
      cancelText: this.$t('reject').toString()
    })
  }

  saveChannelConfig() {
    this.$store.dispatch('save_active_channel_config', this.$route)
  }
}
